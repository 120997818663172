import ENV from './Env.js';
import ApiConstant from '../constants/ApiConstan.js';



export const storeOrder = (_data) => {
    console.log(_data);
    
    return fetch(`${ENV.API_URL}/deal/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
 

export const getMyOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getAllOrders = (_data) => {
    return fetch(`${ENV.API_URL}/deal/all`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
export const getFilteredOrders = (_data) => {
    return fetch(`${ENV.API_URL}/deal/filter`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getOrdersByUser = (_data) => {
    return fetch(`${ENV.API_URL}/deal/show/user`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const updateUserOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const doneUserOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/done/${_data.deal_id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const getOrder = (_data) => {
    return fetch(`${ENV.API_URL}/deal/show/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}


export const getStat = (_data) => {
    return fetch(`${ENV.API_URL}/deal/stat`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

export const addOrderSpecialComposition = (_data) => {
    return fetch(`${ENV.API_URL}/composition/store/special`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}

export const destroyOrderSpecialComposition = (_data) => {
    return fetch(`${ENV.API_URL}/composition/destroy/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}


export const getOrderComposition = (_data) => {
    return fetch(`${ENV.API_URL}/composition/show/${_data.id}`, {
        method: 'GET',
        headers: ENV.getHeaders(), 
    }).then(function (response) {
        return response.json();
    });

}


export const editOrderSpecialComposition = (_data) => {
    return fetch(`${ENV.API_URL}/composition/update/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });
}


