import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSettingList as getSettingListAction,
  updateByKey as updateByKeyAction,
  updateFile as updateFileAction
} from "../../redux/modules/settings";

import OrderConstant from "../../constants/OrderConstant";

import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";
import UiTextArea from "../../components/inputs/UiTextArea";

import "./SettingStyle.scss";

function SettingPage(props) {
  useEffect(() => {
    if (props.login.user) props.getSettingList();
  }, [props.login.user]);

  let list = props.settings.list.map((item, index) => {
    if (item.input_type == 0) {
      return (
        <UiInputText
          key={index}
          placeholder={item.value}
          label={`${item.description}  `}
          onBlur={(val) => {
            props.updateByKey(item.title, val);
          }}
        />
      );
    } else if (item.input_type == 1) {
      return (
        <UiTextArea
          key={index}
          label={`${item.description}  `}
          value={item.value}
          onBlur={(val) => {
            if(item.title == "site_robots"){ 
              props.updateFile("robots.txt", val);
              props.updateByKey(item.title, val);
            } else {
              props.updateByKey(item.title, val);
            }
          }}
        />
      );
    } else {
      <UiInputText
        key={index}
        placeholder={item.value}
        label={`${item.description}  `}
        onBlur={(val) => {
          props.updateByKey(item.title, val);
        }}
      />;
    }
  });

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>

      <section className="page-content">{list}</section>
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getSettingList: getSettingListAction,
  updateByKey: updateByKeyAction,
  updateFile: updateFileAction
})(SettingPage);
