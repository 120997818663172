import React, { Component } from 'react';
import { formatDateDMY, formatDateHM } from "../../utilities/date";

import OrderConstant from "../../constants/OrderConstant";

import "./PrintStyle.scss";

export default class UiCheckPrint extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        let items = (this.props.data ? this.props.data.list ? this.props.data.list : [] : []).map((item, index) => {
            if (item != "Не указан") {
                return (
                    <p key={index}>{item.title} <span>{item.count} шт.</span>  </p>
                )
            }
        });
        let itemsCustom = (this.props.data ? this.props.data.custom_values ? this.props.data.custom_values : [] : []).map((item, index) => {
            return (
                <p key={index}>{item.custom_title} <span>{item.custom_count} шт.</span> <span>{item.custom_price}р</span> </p>
            )
        });


        return (
            <div style={{ width: "100%" }}>
                <table className="check">
                    <tbody>
                        <tr>
                            <td className="check__id" colSpan="2"><p style={{ fontSize: "18pt" }}>
                                ID ЗАКАЗА: <span style={{ fontSize: "18pt" }}>{this.props.data.id}</span> (на {formatDateDMY(this.props.data.date)} &nbsp;c
                                <span style={{ fontSize: "18pt" }}> {this.props.data.time} </span>)

                            </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "top", width: "50%" }}  >
                                <table className="check__left">
                                    <tbody>
                                        <tr>
                                            <td>Имя: </td>
                                            <td>{this.props.data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Телефон:</td>
                                            <td>{this.props.data.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Имя получателя:</td>
                                            <td>{this.props.data.user_name ? this.props.data.user_name : this.props.data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Телефон получателя:</td>
                                            <td>{this.props.data.user_phone ? this.props.data.user_phone : this.props.data.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Заказ принял:</td>
                                            <td>{this.props.data.manager_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Предварительный звонок получателю:</td>
                                            <td>{this.props.data.user_call == 1 ? 'Да' : 'Нет'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{ verticalAlign: "top", width: "50%" }}  >
                                <table className="check__right">
                                    <tbody>
                                        <tr>
                                            <td>Оплата:</td>
                                            <td>{this.props.data.is_pay == 0 ? "НЕ ОПЛАЧЕН" : "ОПЛАЧЕН"}</td>
                                        </tr>
                                        <tr>
                                            <td>Статус:</td>
                                            <td>{OrderConstant.ORDER_TYPES_NAMES[this.props.data.status]}</td>
                                        </tr>
                                        <tr>
                                            <td>Адрес:</td>
                                            <td> {this.props.data.moving_types == 1 && "Самовывоз"} {this.props.data.address_a}</td>
                                        </tr>
                                        <tr className="check__item">
                                            <td><span>Букеты</span></td>
                                            <td>
                                                {items}
                                                {itemsCustom}
                                            </td>
                                        </tr>
                                        <tr className="check__item">
                                            <td>Доставка:</td>
                                            <td>{this.props.data.price_delivery} руб</td>
                                        </tr>
                                        <tr className="check__item">
                                            <td>Сумма:</td>
                                            <td>{this.props.data.price} руб</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt", color: "#000" }}>
                            <td colSpan="2" style={{ paddingLeft: "20pt", paddingRight: "20pt", paddingBottom: "8pt" }}>
                                <b style={{ color: "#000" }}>Ком\фл: </b>
                                {this.props.data.comment}
                            </td>
                        </tr>
                        {this.props.data.postcard ?
                            <tr style={{ fontSize: "10pt", color: "#000" }}>
                                <td colSpan="2" style={{ paddingLeft: "20pt", paddingRight: "20pt", paddingBottom: "8pt" }}>
                                    <b style={{ color: "#000" }}>Записка: </b>
                                    {this.props.data.postcard}
                                </td>
                            </tr> : null}
                        {this.props.data.text ?
                            <tr style={{ fontSize: "10pt", color: "#000" }}>
                                <td colSpan="2" style={{ paddingLeft: "20pt", paddingRight: "20pt", paddingBottom: "8pt" }}>
                                    <b style={{ color: "#000" }}>Ком\кур: </b>
                                    {this.props.data.text}
                                </td>
                            </tr> : null}
                    </tbody>
                </table>

                <hr style={{ width: "100%", height: "1pt", marginTop: "10pt", marginBottom: "10pt", borderWidth: 0, backgroundColor: "#000" }} />

                <table className="check">
                    <tbody>
                        <tr>
                            <td className="check__id" colSpan="2"><p style={{ fontSize: "18pt" }}>
                                ID ЗАКАЗА: <span style={{ fontSize: "18pt" }}>{this.props.data.id}</span> (на {formatDateDMY(this.props.data.date)} &nbsp;c
                                <span style={{ fontSize: "18pt" }}> {this.props.data.time} </span>)
                            </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "top", width: "50%" }}  >
                                <table className="check__left">
                                    <tbody>
                                        <tr>
                                            <td>Имя: </td>
                                            <td>{this.props.data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Телефон:</td>
                                            <td>{this.props.data.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Имя получателя:</td>
                                            <td>{this.props.data.user_name ? this.props.data.user_name : this.props.data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Телефон получателя:</td>
                                            <td>{this.props.data.user_phone ? this.props.data.user_phone : this.props.data.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Заказ принял:</td>
                                            <td>{this.props.data.manager_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Предварительный звонок получателю:</td>
                                            <td>{this.props.data.user_call == 1 ? 'Да' : 'Нет'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{ verticalAlign: "top", width: "50%" }}  >
                                <table className="check__right">
                                    <tbody>
                                        <tr>
                                            <td>Оплата:</td>
                                            <td>{this.props.data.is_pay == 0 ? "НЕ ОПЛАЧЕН" : "ОПЛАЧЕН"}</td>
                                        </tr>
                                        <tr>
                                            <td>Статус:</td>
                                            <td>{OrderConstant.ORDER_TYPES_NAMES[this.props.data.status]}</td>
                                        </tr>
                                        <tr>
                                            <td>Адрес:</td>
                                            <td> {this.props.data.moving_types == 1 && "Самовывоз"} {this.props.data.address_a}</td>
                                        </tr>
                                        <tr className="check__item">
                                            <td><span>Букеты</span></td>
                                            <td>
                                                {items}
                                                {itemsCustom}
                                            </td>
                                        </tr>
                                        <tr className="check__item">
                                            <td>Доставка:</td>
                                            <td>{this.props.data.price_delivery} руб</td>
                                        </tr>
                                        <tr className="check__item">
                                            <td>Сумма:</td>
                                            <td>{this.props.data.price} руб</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr style={{ fontSize: "10pt", color: "#000" }}>
                            <td colSpan="2" style={{ paddingLeft: "20pt", paddingRight: "20pt", paddingBottom: "8pt" }}>
                                <b style={{ color: "#000" }}>Ком\фл: </b>
                                {this.props.data.comment}
                            </td>
                        </tr>
                        {this.props.data.postcard ?
                            <tr style={{ fontSize: "10pt", color: "#000" }}>
                                <td colSpan="2" style={{ paddingLeft: "20pt", paddingRight: "20pt", paddingBottom: "8pt" }}>
                                    <b style={{ color: "#000" }}>Записка: </b>
                                    {this.props.data.postcard}
                                </td>
                            </tr> : null}
                        {this.props.data.text ?
                            <tr style={{ fontSize: "10pt", color: "#000" }}>
                                <td colSpan="2" style={{ paddingLeft: "20pt", paddingRight: "20pt", paddingBottom: "8pt" }}>
                                    <b style={{ color: "#000" }}>Ком\кур: </b>
                                    {this.props.data.text}
                                </td>
                            </tr> : null}
                    </tbody>
                </table>


            </div>
        );
    }
}
