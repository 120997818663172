export default {
    STORE_KEY: 'a56z0fzrNpl^2',
    //Dev
    //HOST_URL: 'http://klumba.devkot.ru/',
    //API_URL: 'http://klumba.devkot.ru/server/public/api/v1',
    //PUBLIC_URL: 'http://klumba.devkot.ru/server/public/uploads/',

    HOST_URL: 'https://chita.klumba.store/',
    API_URL: 'https://klumba-store.ru/server/public/api/v1',
    PUBLIC_URL: 'https://klumba-store.ru/server/public/uploads/',

    //Prod
    //HOST_URL: 'https://klumba.store/',
    //API_URL: 'https://klumba-store.ru/mos-server/public/api/v1',
    //PUBLIC_URL: 'https://klumba-store.ru/mos-server/public/uploads/',
    //STORAGE_URL: '',
   
    getHeaders: function () {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }
    }
  };