import React, { useState } from "react";
import InputMask from "react-input-mask";

import "./UiInputText.scss";



const UiTextInputMasked = (props) => {
  const [value, setValue] = useState("");
  return (
    <div className="input-wrap">
      {props.label ? <label>{props.label}</label> : null}
      <InputMask
        className={
          "input-text" +
          (props.disabled ? " disabled" : "") +
          (props.info ? " info-active" : "") +
          (props.small ? " small" : "")
        }
        mask="+7 (999) 999-9999"
        disabled={props.disabled}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e.target.value)
        }}
      />
      {props.info ? (
        <div className="input-text-info-wrap">
          <p className="input-text-info">{props.info}</p>
        </div>
      ) : null}
      {props.error && value === "" ? (
        <p className="input-text-warn">{props.errorText}</p>
      ) : null}
    </div>
  );

}
export default UiTextInputMasked;