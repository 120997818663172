import React, { useEffect, useState } from "react";
import { addFile } from "../../services/Storage";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import demo0 from "../../assets/images/demo/1447_b.jpeg";

import OrderConstant from "../../constants/OrderConstant";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { inArray, inArrayValue, unCheckInArray } from "../../utilities/array";
import { calculateOrder } from "../../utilities/calculate";

import { addFileBase64 } from "../../services/Storage";
import getCroppedImg from "../../services/CropImage";

import { ReactComponent as SvgCalendar } from "../../assets/images/deal/calendar.svg";
import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";
import { ReactComponent as SvgInfo } from "../../assets/images/deal/info.svg";
import { ReactComponent as SvgCube } from "../../assets/images/deal/cube.svg";
import { ReactComponent as SvgPeople } from "../../assets/images/deal/people.svg";

import UiInputText from "../inputs/UiInputText";
import UiModal from "./UiModal";

import "./UiModalDeal.scss";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";

import UiDropDown from "../inputs/UiDropDown";
import UiTextArea from "../inputs/UiTextArea";
import UiSwitch from "../switch/UiSwitch";
import UiCardAddPhoto from "../cards/UiCardAddPhoto";
import UiTextInputMasked from "../inputs/UiTextInputMasked";
import UiButtonColor from "../button/UiButtonColor";
import UiTableAdaptive from "../table/UiTableAdaptive";

import Env from "../../services/Env";

function UiModalProductAdd(props) {

  const liftTypes = OrderConstant.LIFT_TYPES;


  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [load, setLoad] = useState(false);

  const [order, setOrder] = useState({});
  const [state, setState] = useState({ isDialogCrop: false, savedImages: [], imagesList: [] });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",
    borderRadius: '10px',
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
  });


  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "Название" },
    { sort: false, title: "Цена" },
  ];
  const tableListOrders = [
    [
      { button: "delete" },
      { image: demo0 },
      { text: "Название" },
      { text: "5 000 ₽" },
    ],
  ];


  const mainPhotosTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
  ];
  const mainPhotosTables = [
    [
      { button: "delete" },
      { image: demo0 },
    ],
  ];

  function uploadList(file) {
    setLoad(true);
    addFile(file, 2).then((res) => {
      console.log(res);
      setLoad(false);
      if (res.success) {
        setImage(res.response);
        setImageUrl(Env.PUBLIC_URL + res.response);
      }
    });
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.imagesList,
      result.source.index,
      result.destination.index
    );

    console.log(items);
    setState({
      ...state,
      imagesList: items
    });
  }



  useEffect(() => {

  }, []);

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = order;
    if (val != "price") {
      _obj["price"] = calculateOrder(props.prices, order);
    }
    _obj[key] = val;
    setOrder({ ..._obj });
  }

  function _onSaveImage(_croppedAreaPixels) {
    setState({ ...state, isDialogCrop: false });
    getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      setState({ ...state, selectedFileName: res, });
      let arr = this.state.savedImages;
      arr.splice(0, 1);
      setState({ ...state, savedImages: arr });
      this.upload();

    });
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function upload(e) {
    setState({
      ...state, imageLoad: true
    });
    addFileBase64(state.selectedFileName, 0).then((res) => {
      setState({
        ...state, imageLoad: false
      });
      if (res.success) {
        let arr = state.data.imgs;
        arr.push(res.response);
        setState({
          ...state,
          imagesList: this._convertImgs(arr)
        })
        this._edit("imgs", arr);

        if (state.savedImages.length > 0) setState({
          ...state, isDialogCrop: true
        });
      } else {
        console.log(res);
      }
    });
  }

  return (
    <UiModal name="modal" show={props.modalActive}>
      <div className="modal-deal">
        <div className="modal-deal-wrap">
          {/**
           * modal left side - static
           */}
          <div className="modal-deal-about add">
            {/**
             * modal title
             */}
            <div className="modal-deal-about-title">
              <div className="modal-deal-about-title-wrap">
                <h3>Добавить товар</h3>
              </div>
            </div>
            {/**
             * modal notifications + msgs
             */}
            <div className="modal-deal-notes">
              <div className="modal-deal-notes-list-wrap">
                <div className="modal-deal-info">
                  <div className="modal-deal-info-form">
                    <UiInputText
                      label="Название товара:"
                      placeholder="Название товара"
                      onChange={(val) => {
                        updateOrderObj("order_name", val);
                      }}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiDropDown
                      label="Категория:"
                      items={liftTypes}
                      onChange={(val) => updateOrderObj("elevator_type_b", val)}
                    />
                  </div>
                  <div className="modal-deal-info-form">
                    <UiTextArea label="Краткое описание" />
                  </div>

                  <div className="modal-deal-info-form">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {state.imagesList.map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >

                                    <label className="inter-add-image" key={index}>
                                      <div className="edit-image-wrap">
                                        <img key={index} src={Env.PUBLIC_URL + item.content} className="image" />
                                        <button
                                          className="edit-image-button"
                                          onClick={() => this._remImg(index, item.content)}
                                        >
                                          Удалить
                                        </button>
                                      </div>
                                    </label>


                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>

                  <div className="modal-deal-info-form">
                    <label className="edit-image-button">
                      <b>Добавить изображения</b>
                      <input
                        type="file"
                        multiple="multiple"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          console.log(e.target.files)
                          if (e.target.files.length > 0) {
                            for (let i = 0; i < e.target.files.length; i++) {
                              let arr = state.savedImages; 
                              getBase64(e.target.files[i], (result) => { 
                                arr.push(result);
                                setState({
                                  ...state,
                                  savedImages: arr,
                                  base64Image: result,
                                  isDialogCrop: true,
                                });
                              });
                            }
                          }
                        }}
                      />
                    </label>
                  </div>


                  <div className="modal-deal-info-form">
                    <UiButtonColor
                      color="primary"
                      text="Создать"
                      small={true}
                      onClick={() => {
                        if (
                          order.address_a != "" &&
                          order.address_b != "" &&
                          order.date_dmy != null &&
                          order.time_hm != null
                        ) {
                          if (order.volume_car != "" && order.movers != "") {
                            let _order = order;
                            //_order.price = calculateOrder(order);
                            props.onCreate(_order);
                          } else {
                            alert("Заполните объем и коло-во грузчиков");
                          }
                        } else {
                          alert("Заполните поля адрес и дату");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**
           * modal right side - tabs
           */}
          <div className="modal-deal-info-wrap">
            {/** close button */}
            <button
              className="modal-deal-info-close"
              onClick={() => props.modalClose()}
            >
              <SvgClose className="modal-deal-info-close-icon" />
            </button>
            <div className="modal-deal-info">
              <h3>Фотографии, размеры, варианты</h3>
              <div className="modal-deal-info-form">
                <div className="added-list">
                  <UiTableAdaptive
                    titleList={clientsTitles}
                    grid={clientsTitles.length}
                    tableList={tableListOrders}
                    search={false}
                  />
                </div>
                <UiInputText
                  label="Фотография"
                  type="file"
                  onChange={(val) => updateOrderObj("movers", val)}
                />
                <UiInputText
                  label="Название размера\варианта"
                  onChange={(val) => updateOrderObj("movers", val)}
                />
                <UiInputText
                  label="Цена"
                  onChange={(val) => updateOrderObj("movers", val)}
                />
                <UiButtonColor
                  color="primary"
                  text="Добавить размер\вариант"
                  small={true}
                  onClick={() => { }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> 

      <UiModalImageCrop
    modalOpen={true}
    base64Image={state.savedImages[0]}
    onSave={(val) => _onSaveImage(val)}
    cancelClick={() => setState({...state, isDialogCrop: false })}
    />

    </UiModal>
  
          
  );
}

export default UiModalProductAdd;
